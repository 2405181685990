import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BillingNav from '../dashboard/billing-nav';
import { front_end_addr } from '../settings';

const Overview = ({ onLogout, user }) => {
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    handleOverview();
  }, []);

  const handleOverview = async () => {
    try {
      const response = await axios.post('https://threepio.io/billing/get_overview/', {
        username: user.username,
      });
      setPlan(response.data.plan_name);
    } catch (error) {
      console.error('Error fetching plans: ', error);
    }
  };

  return (
    <>
      <BillingNav />
      <div className="overview-page">
        <h1>Overview</h1>
        <div>
          <h2>Current Plan: {plan}</h2>
        </div>
        <button onClick={() => (window.location.href = '/dashboard/billing/plans')}>
          Select Plan OR Cancel Plan
        </button>
        {/* Add more content here as needed */}
      </div>
    </>
  );
};

export default Overview;
