import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login';
import Signup from './components/signup';
import Dashboard from './components/dashboard';
import Pricing from './components/Pricing';
import Database from './components/dashboard/Database';
import Create from './components/dashboard/Create';
import VideoDetail from './components/dashboard/video-detail';
import Folders from './components/dashboard/Folders';
import FolderDetail from './components/dashboard/folder-detail';
import Billing from './components/dashboard/Billing';
import Overview from './components/billing/Overview';
import Plans from './components/billing/Plans';
import PaymentMethods from './components/billing/payment-methods';
import BillingHistory from './components/billing/billing-history';
import BillingPreferences from './components/billing/billing-preferences';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Replace '#root' with the id of your app's root element


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({ username: 'Guest', profilePicture: 'default-profile-url' });

  useEffect(() => {
    const authState = localStorage.getItem('isAuthenticated');
    const userState = localStorage.getItem('user');
    if (authState && JSON.parse(authState)) {
      setIsAuthenticated(true);
    }
    if (userState) {
      try {
        setUser(JSON.parse(userState));
      } catch (error) {
        console.error('Error parsing user state from localStorage', error);
        localStorage.removeItem('user');
      }
    }
    setIsLoading(false);
  }, []);

  const handleLogin = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    localStorage.setItem('isAuthenticated', JSON.stringify(true));
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUser({ username: 'Guest', profilePicture: 'default-profile-url' });
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/dashboard/*" element={isAuthenticated ? <Dashboard onLogout={handleLogout} user={user} /> : <Navigate to="/login" />} />
        <Route path="/dashboard/database" element={isAuthenticated ? <Database onLogout={handleLogout} user={user} /> : <Navigate to="/login" />} />
        <Route path="/dashboard/create" element={isAuthenticated ? <Create onLogout={handleLogout} user={user} /> : <Navigate to="/login" />} />
        <Route path="/dashboard/folders" element={isAuthenticated ? <Folders onLogout={handleLogout} user={user} /> : <Navigate to="/login" />} />
        <Route path="/dashboard/folders/:folderId" element={isAuthenticated ? <FolderDetail onLogout={handleLogout} user={user} /> : <Navigate to="/login" />} />
        <Route path="/video/:videoId" element={<VideoDetail onLogout={handleLogout} user={user} />} />
        <Route path="/dashboard/billing/*" element={isAuthenticated ? <Billing onLogout={handleLogout} user={user} /> : <Navigate to="/login" />} >
          <Route path="overview" element={<Overview user={user}/>} />
          <Route path="plans" element={<Plans user={user}/>} />
          <Route path="payment-methods" element={<PaymentMethods user={user}/>} />
          <Route path="billing-history" element={<BillingHistory user={user}/>} />
          <Route path="billing-preferences" element={<BillingPreferences user={user}/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
