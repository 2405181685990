import React from 'react';
import BillingNav from '../dashboard/billing-nav';

const BillingHistory = ({ onLogout, user }) => {
  return (
    <>
      <BillingNav />
      <h2>Billing History</h2>
      <p>No billing history available.</p>
    </>
  );
};

export default BillingHistory;
