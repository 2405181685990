import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/login.css';
import Navbar from './navbar';
import { ip_addr } from './settings';

const Login = ({ onLogin }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      console.log("Before post");
      let token = getCookie('csrftoken');
      const response = await axios.post('https://api.threepio.io/accounts/login/', formData, {
        withCredentials: true,
        headers: {
          'X-CSRFToken': token,
          'Content-Type': 'application/json'
        }
      });
      console.log("After post")

      if (response.data.success) {
        console.log('User logged in successfully', response.data);
        const userData = {
          username: response.data.username,
          profilePicture: 'profile-picture-url'
        };
        onLogin(userData);
        navigate('/dashboard/database', { state: userData });
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setError(`Error: ${error.response.data.message || 'An error occurred during login.'}`);
      } else {
        console.error('Error:', error);
        setError('An error occurred during login.');
      }
    }
  };

  const getCookie = (name) => {
    console.log("INSIDE COOKIE");
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      console.log(document.cookie)
      console.log(decodeURIComponent(document.cookie))
      const cookies = document.cookie.split(';');
      for (let i = 0; cookies.length > i; i++) {
        const cookie = cookies[i].trim();
	console.log("cookie name:", cookie.substring(0, name.length + 1))
        if (cookie.substring(0, name.length + 1) === `${name}=`) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
 	  console.log(cookieValue);
          break;
        }
      }
    }
    
    console.log("At the end of cookie");
    return cookieValue;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <div className = "login">
       <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <div className="login-container">
        <form className="form-box" onSubmit={handleSubmit}>
          <h2>Login</h2>
          <div className="input-field">
            <label>Username:</label>
            <input type="text" name="username" placeholder="Username" required />
          </div>
          <div className="input-field">
            <label>Password:</label>
            <input type="password" name="password" placeholder="Password" required />
          </div>
          <div className="button-group">
            <button type="submit" className="button-primary">Login</button>
          </div>
          {error && <p className="error-message">{error}</p>}
          <p>If you don't have an account, <a className="text-link" href="/signup">Sign Up</a></p>
        </form>
      </div>
    </div>
  );
};

export default Login;
