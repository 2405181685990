import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import the configured Axios instance
import '../../style/Database.css';
import Layout from '../Layout';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Database = ({ onLogout, user }) => {
  const [videos, setVideos] = useState([]);
  const [query, setQuery] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [timestamps, setTimestamps] = useState({});
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchVideos();
    fetchFolders();
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await axios.get('https://api.threepio.io/transcribe/saved_videos/');
      if (response.data && typeof response.data === 'object') {
        setVideos(response.data.videos || []);
        setFilteredVideos(response.data.videos || []);
        try {
          setTimestamps(JSON.parse(response.data.timestamps_json || '{}'));
        } catch (parseError) {
          console.error('Error parsing timestamps JSON:', parseError);
        }
      } else {
        console.error('Invalid JSON response');
      }
    } catch (error) {
      console.error('Error fetching videos', error);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await axios.get('https://api.threepio.io/transcribe/folders/');
      setFolders(response.data);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const handleSearch = useCallback(async () => {
    try {
      const response = await axios.get('https://api.threepio.io/transcribe/saved_videos/', {
        params: { q: query },
      });

      if (response.data && typeof response.data === 'object') {
        setFilteredVideos(response.data.videos || []);
        try {
          setTimestamps(JSON.parse(response.data.timestamps_json || '{}'));
        } catch (parseError) {
          console.error('Error parsing timestamps JSON:', parseError);
        }
      } else {
        console.error('Invalid JSON response');
      }
    } catch (error) {
      console.error('Error searching videos', error);
    }
  }, [query]);

  useEffect(() => {
    if (query) {
      handleSearch();
    } else {
      setFilteredVideos(videos);
      setTimestamps({});
    }
  }, [query, videos, handleSearch]);

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  const handleCheckboxChange = (videoId) => {
    const updatedSelectedVideos = selectedVideos.includes(videoId)
      ? selectedVideos.filter((id) => id !== videoId)
      : [...selectedVideos, videoId];
    setSelectedVideos(updatedSelectedVideos);
  };

  const handleMoveToFolder = async (folderId) => {
    if (folderId === 'create-folder') {
      setIsCreateModalOpen(true);
    } else {
      try {
        await axios.post('https://api.threepio.io/transcribe/folders/move/', { folderId, videoIds: selectedVideos });
        alert('Videos moved successfully');
        setSelectedVideos([]);
        fetchVideos(); // Refresh the videos list
      } catch (error) {
        console.error('Error moving videos:', error);
        alert('Error moving videos');
      }
    }
  };

  const handleCreateFolder = async () => {
    if (newFolderName.trim()) {
      try {
        const response = await axios.post('https://api.threepio.io/transcribe/folders/create/', { name: newFolderName });
        if (response.data && response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          setIsCreateModalOpen(false);
          setNewFolderName('');
          setErrorMessage('');
          fetchFolders(); // Refresh the folders list
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          console.error('Error creating folder:', error);
        }
      }
    } else {
      setErrorMessage('Folder name is required');
    }
  };

  return (
    <Layout onLogout={onLogout} user={user}>
      <div className="database-container">
        <div className="header-row">
          <h1>Saved Videos</h1>
          <div className="actions">
            {selectedVideos.length > 0 && (
              <div className="move-to-folder">
                <select onChange={(e) => handleMoveToFolder(e.target.value)}>
                  <option value="">Move to</option>
                  {folders.map((folder) => (
                    <option key={folder.id} value={folder.id}>{folder.name}</option>
                  ))}
                  <option value="create-folder">Create Folder</option>
                </select>
              </div>
            )}
            <button className="create-button" onClick={() => navigate('/dashboard/create')}>
              Create
            </button>
          </div>
        </div>
        <input
          type="text"
          placeholder="Search videos..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="search-input"
        />
        <table className="video-table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Date Uploaded</th>
              <th>Length</th>
              <th>Language</th>
            </tr>
          </thead>
          <tbody>
            {filteredVideos.map((video) => (
              <tr key={video.id} className="video-item">
                <td>
                  <input
                    type="checkbox"
                    checked={selectedVideos.includes(video.id)}
                    onChange={() => handleCheckboxChange(video.id)}
                  />
                </td>
                <td className="video-link" onClick={() => handleVideoClick(video.id)}>{video.title}</td>
                <td>{new Date(video.created_at).toLocaleDateString()}</td>
                <td>{video.length}</td>
                <td>{video.language}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {timestamps && Object.keys(timestamps).length > 0 && (
          <div className="timestamps">
            <strong>Search Matches:</strong>
            <ul>
              {Object.keys(timestamps).map((videoId) => (
                <li key={videoId}>
                  {timestamps[videoId].map((timestamp, index) => (
                    <span key={index}>{timestamp}</span>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        )}

        <Modal
          isOpen={isCreateModalOpen}
          onRequestClose={() => setIsCreateModalOpen(false)}
          contentLabel="Create Folder"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>Create Folder</h2>
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="Folder name"
            className="create-folder-input"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button onClick={handleCreateFolder} className="create-folder-button">Create</button>
          <button onClick={() => setIsCreateModalOpen(false)} className="cancel-button">Cancel</button>
        </Modal>
      </div>
    </Layout>
  );
};

export default Database;
