import React, { useState } from 'react';
import BillingNav from '../dashboard/billing-nav';

const PaymentMethods = ({ onLogout, user }) => {
  const [paymentMethod, setPaymentMethod] = useState('');

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    // Here you would integrate with Stripe API to handle payment method submission
    console.log('Selected Payment Method:', paymentMethod);
  };

  return (
    <>
      <BillingNav />
      <div>
        <h2>Payment Methods</h2>
        <form onSubmit={handlePaymentSubmit}>
          <div>
            <label htmlFor="paymentMethod">Select Payment Method:</label>
            <select id="paymentMethod" value={paymentMethod} onChange={handlePaymentMethodChange}>
              <option value="">Select</option>
              <option value="creditCard">Credit Card</option>
              <option value="paypal">PayPal</option>
            </select>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};

export default PaymentMethods;
