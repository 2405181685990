import React from 'react';
import BillingNav from '../dashboard/billing-nav';

const BillingPreferences = ({ onLogout, user }) => {
  return (
    <>
      <BillingNav />
      <h2>Billing Preferences</h2>
      <p>Manage your billing preferences here.</p>
    </>
  );
};

export default BillingPreferences;
