import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../style/billing-nav.css';

const BillingNav = () => {

  return (
    <div className="billing-nav">
      <Link to="/dashboard/billing/overview">Overview</Link>
      <Link to="/dashboard/billing/plans">Plans</Link>
      <Link to="/dashboard/billing/payment-methods">Payment Methods</Link>
      <Link to="/dashboard/billing/billing-history">Billing History</Link>
      <Link to="/dashboard/billing/billing-preferences" >Billing Preferences</Link>
    </div>
  );
};

export default BillingNav;
